import { AuthService } from '@auth0/auth0-angular';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { initFlowbite } from 'flowbite';

import { CookiesService } from '../../core/services/common/cookies.service';
import { catchError, map, of, take } from 'rxjs';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent implements OnInit {
  picture: string | null | undefined = '';

  constructor(
    private cookiesService: CookiesService,
    public auth0: AuthService
  ) {}

  ngOnInit(): void {
    initFlowbite();
    this.fetchUserPicture();
  }

  private fetchUserPicture(): void {
    this.auth0.user$.pipe(
      take(1),
      map(response => response?.picture),
      catchError(error => {
        console.error('Erro ao obter a imagem do usuário:', error);
        return of(null);
      })
    ).subscribe(picture => {
      this.picture = picture;
    });
  }

  logout(): void {
    this.auth0.logout();
    this.cookiesService.removeItem('TOKEN');
  }
}
