import { AuthService } from '@auth0/auth0-angular';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CookiesService } from '../../core/services/common/cookies.service';
import { MenuComponent } from '../menu/menu.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, MenuComponent, RouterModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {

  constructor(
    private cookiesService: CookiesService,
    private auth0: AuthService
  ) { }

  ngOnInit(): void {
  }

  logout(): void {
    this.auth0.logout();
    this.cookiesService.removeItem('TOKEN');
  }
}
