<div class="top-0 w-full bg-neutral-50 py-6 px-10 lg:ps-[320px] flex flex-row">
    <div class="flex-grow">
        <!-- Conteúdo da primeira div -->

    </div>
    <div class="mt-auto">
        <img id="avatarButton" type="button" data-dropdown-toggle="userDropdown" data-dropdown-placement="bottom-start"
            class="size-10 border-2 rounded-full cursor-pointer" [src]="picture" alt="User dropdown">

        <div id="userDropdown"
            class="hidden bg-white absolute right-0 left-auto mt-40 z-40 divide-y divide-gray-100 rounded-lg shadow w-auto max-w-80">
            <div class="px-4 py-3 text-sm text-gray-900 dark:text-white">
                <div *ngIf="auth0.user$ | async as user">{{ user.name }}</div>
                <div *ngIf="auth0.user$ | async as user" class="font-medium truncate">{{ user.email }}</div>
            </div>
            <div class="py-1 hover:cursor-pointer">
                <a (click)="logout()"
                    class="block px-4 py-2 text-sm text-gray-700 hover:text-blue-500 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sair</a>
            </div>
        </div>
    </div>
</div>